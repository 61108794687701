<template>
  <div class="section">
    <vue-good-table
      max-height="100vh"
      :columns="columns"
      :rows="result.data"
      :fixed-header="true"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Nama Barang -->
        <span v-if="props.column.field === 'name'">
          <b-img
            width="45"
            height="45"
            class="border-8 mr-1"
            :src="props.row.logo_url"
          />
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <div class="d-flex">
              <b-button class="mr-1 px-1 border-0 bg-pink" @click="deleteData(props.row.uuid)">
                <feather-icon
                  size="18"
                  class="text-danger"
                  icon="Trash2Icon"
                />
              </b-button>
              <b-button class="bg-white" @click="editItem(props.row)">
                <b-img :src="require('@/assets/images/icons/Process2.svg')" /> Ubah
              </b-button>
            </div>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!-- Pagination -->
    <div
      v-if="result"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap ">
          Tampilkan 1 ke {{ filter.per_page }} dari {{ result.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BRow, BCol, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton,
} from 'bootstrap-vue'

// import ModalAddProductParent from '@/components/MasterProduct/Modal/ModalAddProductParent.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    // ModalAddProductParent,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    typeTable: {
      type: String,
    },
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    deleteData: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'formatted_id',
        },
        {
          label: 'Nama Barang',
          field: 'name',
        },
        {
          label: 'Deskripsi Barang',
          field: 'description',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      items: [],
      search: '',
      filter: {
        search: '',
        sort_type: 'desc',
        per_page: 10,
      },
    }
  },
  watch: {
    // result(value) {
    //   this.rows = value.data
    //   this.items = value.data.data
    // },
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.vgt-responsive {
  height: calc(100vh - 202px) !important;
  background-color: #fff;
}
</style>
