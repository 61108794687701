<template>
  <div class="">
    <TableBarang
      :result="result"
      :delete-data="deleteItem"
      :get-data="getItem"
      :edit-item="editItem"
      @filter="getFilter"
    />

    <ModalBarang
      :form-data="formPayload"
      :create-item="createItem"
      :edit-id="editId"
      :edit-item="editItem"
      :update-item="updateItem"
      :clean-up-form="cleanUpForm"
      :icon-bank="iconBank"
      @getPayload="getPayload"
    />

  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import {
  VBToggle,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import TableBarang from '@/components/Master/Barang/TableBarang.vue'
import ModalBarang from '@/components/Master/Barang/Modal/ModalBarang.vue'

export default {
  title() {
    return 'Master Barang'
  },
  components: {
    TableBarang,
    ModalBarang,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      iconBank: null,
      formPayload: {
        name: '',
        description: '',
        logo: '',
      },
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
    }
  },
  computed: {
    ...mapState({
      items: state => state.item.items,
    }),
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getItem()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getItem()
  },
  methods: {
    ...mapActions('item', ['getData', 'postData', 'deleteData', 'showData']),
    getPayload(value) {
      this.formPayload = value
    },
    getFilter(value) {
      this.filter = value
    },
    cleanUpForm() {
      this.editId = null
      this.iconBank = null
      this.formPayload = {
        name: '',
        description: '',
        logo: '',
      }
      this.validations = ''
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = item.uuid
      this.iconBank = item.logo_url
      this.$store.commit('item/SET_FORM_PAYLOAD', this.formPayload = {
        name: item.name,
        description: item.description,
      })
      this.$bvModal.show('modal-item')
    },
    async getItem(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      await this.getData({
        params: queryParams,
      })
      this.result = this.items
      this.currentPage = this.items.current_page
      this.isLoading = false
    },
    async createItem() {
      const form = this.preparePayload()
      this.isLoading = true
      const response = await this.postData({
        id: '',
        payload: form,
      })
      if (response) {
        this.isLoading = false
        this.$bvModal.hide('modal-item')
        this.getItem()
        successNotification(this, 'Success', 'Barang berhasil di buat')
        this.cleanUpForm()
      }
    },
    async updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')
      const response = await this.postData({
        id: this.editId,
        payload: form,
      })
      if (response) {
        this.getItem()
        successNotification(this, 'Success', 'Barang berhasil di ubah!')
        this.cleanUpForm()
        this.isLoading = false
        this.$bvModal.hide('modal-item')
      }
    },
    async deleteItem(uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await this.deleteData({
            id: uuid,
          })
          this.getItem()
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'Barang berhasil dihapus',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }
      return form
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

#app {
  background: #f6f7fa;
}
</style>
